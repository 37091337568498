$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.right-side {
  .login-form {
    // border: 1px solid red;
    padding: 0 88px !important;

    @media (max-width: 768px) {
      padding: 0 10px !important;
    }
    // margin-right: 100px;
    // align-items: center;
    // margin-top: 51px;
  }
  h1 {
    color: $textBlack;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }
  span {
    // border: 1px solid red;
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    // padding-bottom: 32px;
    // margin-bottom: 32px !important;
    .green {
      color: $maiGreen;
      &:hover {
        cursor: pointer;
      }
    }
  }

  .input {
    &:first-of-type {
      padding-top: 32px;
    }
    margin-bottom: 16px;
  }
  .button {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}
