$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;

.cashier {
  .title {
    margin-bottom: 32px !important;
  }
  .main-section {
    margin-top: 32px;
    .table-option {
      margin-top: 32px !important;
      margin-bottom: 32px;
    }
    .options {
      margin-bottom: 32px;
    }
    .input {
      margin-bottom: 16px;

      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .input--options {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        margin-top: 0;
      }
      .input--link {
        color: $textGray;
        margin-top: 8px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        margin-top: 0;
      }

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px !important;
      }
      .button {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
}

.company-profile--data {
  padding: 0;
  //   padding-top: 24px;
  .single-item {
    // direction: auto;
    margin-bottom: 16px;
    padding: 0;
    .category-option {
      border: 2px solid $mainColor;
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 8px 20px;
      border-radius: 10px;
      margin: 0;
      margin-inline-end: 8px;
      //   margin-top: 16px !important;
    }
    p {
      padding: 0px;
      color: $mainColor;
      margin-bottom: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 255px;
      height: 150px;
      margin-inline-end: 8px;
    }

    span {
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.green {
        color: $mainColor;
        font-weight: 500;
      }
      &.icon {
        color: $textGray;
        svg {
          margin-inline-end: 8px;
          transition: 0.3s all;
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.pagination {
  margin: 24px 0 !important;
}
.barcode-message {
  text-align: center;
}

.barcode-canvas {
  position: relative;
  overflow: hidden;
  display: none;
  padding-bottom: 56.25%;
  line-height: 0;

  video {
    z-index: 10;
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    right: 50%;
    top: 50%;
    bottom: 50%;
    background: white;
  }

  canvas.drawingBuffer {
    z-index: 20;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }

  br {
    display: none;
  }
}
