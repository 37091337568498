$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$bacgroundWhite: #ffffff;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.pagination-item {
  color: $textBlack;
  background: transparent;
  border: 0;
  font-size: 16px;
  font-weight: 400;
}

.pagination-item--selected {
  color: $bacgroundWhite;
  height: max-content;
  background: $highlightBlue;
  border: 2px solid $highlightBlue;
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  span {
    color: $textBlack;
    font-weight: 500;
  }
}
.pagination-item--type {
  height: max-content;
  background: transparent;
  border: 0px;
  font-size: 16px;
  font-weight: 400;

  &.active {
    color: $highlightBlue;
  }
}
