.main-title {
  color: var(--Dark-Violet, #340040);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 80% */
  letter-spacing: -0.376px;
}
.sub-title {
  color: rgba(52, 0, 64, 0.5);

  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.376px;
  margin: 50px 0;
}

ul {
  list-style: none;
}
li {
  color: var(--Dark-Violet, #340040);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 160% */
  letter-spacing: -0.376px;
}

.title-partners {
  color: var(--Dark-Violet, #340040);
  text-align: center;
  font-family: Poppins;
  font-size: 44px;
  font-style: normal;
  font-weight: 500;
  /* line-height: 20.249px; 46.022% */
  /* letter-spacing: -0.376px; */
  margin: 50px 0;
}

.btn-partners {
  border: 2px solid #340040;
  color: #340040;
  border-radius: 50%;
  background: transparent;
  width: 250px !important;
  height: 250px !important;
  /* padding: auto; */
  padding: 0px;
  margin: 0px;
  /* border-radius: 8px; */
  color: var(--Dark-Violet, #340040);
  /* font-family: Pragati Narrow; */
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  /* line-height: normal; */
  /* margin: 50px 0; */
}

.socialMedia:hover {
  scale: 1.5;
  transition: all 0.4s;
}
.link {
  color: unset;
  text-decoration: none;
}
.link:hover {
  cursor: pointer;
  color: unset;
}
.footer {
  background-color: #340040;
  /* height: 302px; */
  margin-top: 176px;
  padding: 32px 100px;
}
.link:hover {
  transition: 0.4s linear;
  transform: scale(1.3);
  cursor: pointer;
}

.footer-title {
  color: white;
  font-size: 18px;
  text-transform: uppercase;
  line-height: 18px;
  font-weight: 500;
}

.footer div {
  color: white !important;
}
.downlod {
  color: white;
  font-size: 14px;
  line-height: 27px;
  font-weight: 400;
}
.about-us {
  /* border: 1px solid red; */
}

.bg-f-1 {
  position: absolute;
  left: -20%;
}

.bg-f-2 {
  position: absolute;
  left: 70%;
  bottom: -30%;
}

.bg-f-3 {
  position: absolute;
  left: -5%;
  top: -2%;
}

.about-us--title {
  color: white;
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 48px;
  margin-top: 175px;
  padding: 0;
}

.about-us--founder-container img {
  border-radius: 50%;
  /* width: 176px; */
  /* height: 176px; */
  margin: 0;
  padding: 0;
}

.about-us--founder-container .single-card {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid red; */
  padding-right: 97px;
}
.about-us--founder-container div {
  color: white;
  font-size: 21px;
  font-weight: 500;
  line-height: 32px;
  margin-top: 16px;
}

.about-us--founder-container p {
  color: white;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
  padding: 0 5px;
}
/* .about-us {
  height: 1200px;
} */

.form-container {
  /* margin: 264px 0; */

  border-radius: 15px;
  background: var(--light-vio, #9b84a4);
  /* margin-top: 141px; */
  /* margin-bottom: 0; 
  padd*/
  padding: 24px !important;
  /* border: 1px solid red; */
}
.andorid {
  right: 2px;
}
label {
  color: var(--Dark-Violet, #340040);
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-inline-start: 2px;
  margin-bottom: 5px;
}

.form-btn {
  background: #340040;
  border: 1px solid #340040;
  color: white;
  width: auto;
  border-radius: 8px;

  padding: 8px 10px;
  margin-top: 24px;
  transition: 0.4s linear;
}
.form-btn:hover {
  background: transparent;
  border: 1px solid #340040;
  color: #340040;
  transition: 0.4s linear;
  width: auto;
  border-radius: 8px;

  padding: 8px 10px;
  margin-top: 24px;
}
input {
  height: 45px;
  width: 100%;
  border: 0.5px solid #340040;
  border-radius: 8px;
  margin-bottom: 24px !important;
}

textarea {
  height: 94px;
  width: 100%;
  border: 0.5px solid #340040;
  border-radius: 8px;
}

.form-container {
  /* border: 0.5px solid var(--main-color); */
  padding: 28px 56px 56px 56px;
  border-radius: 8px;
}

.form-title {
  color: #525252;
  font-size: 32px;
  text-align: center;
  line-height: 48px;
  font-weight: 400;
  margin-bottom: 46px;
}

input::placeholder,
textarea::placeholder {
  color: #989898;
  font-size: 16px;
  line-height: 21px;
  font-weight: 300;
}

.form {
  margin-top: 0px !important;
}
.feature2,
.feature1 {
  height: 769px;
}

@media (max-width: 1115px) {
  .footer {
    height: auto;
    padding: 17px 50px;
  }
  .feature2,
  .feature1,
  .padding {
    padding: 0;
  }

  .padding {
    margin: 0;
  }

  .iphone2 .frame,
  .iphone1 .frame,
  .iphone1 .body,
  .iphone2 .body {
    width: 180.53px;
    height: 350.51px;
  }

  /* .iphone1, */
  .iphone2 {
    left: 25%;
    top: 6%;
  }

  .iphone1 {
    left: 20%;
    top: 6%;
  }

  .custom-container {
    padding: 0 50px;
  }
  .form-container {
    padding: 18px 36px 36px 36px;
  }
  .our-story--text {
    /* font-size: 14px; */
    padding: 0;
  }
  .about-us--founder-container p {
    font-size: 14px;
  }
  .iphone {
    left: 130px;
  }
  .main-section--right p {
    width: 100%;
  }
}

@media (max-width: 765px) {
  .bg-0 {
    width: 340px;
  }
  .iphone {
    left: 210px;
  }
  .feature2,
  .feature1,
  .padding {
    padding: 0;
  }

  .padding {
    margin: 0;
  }

  .iphone2 .frame,
  .iphone1 .frame,
  .iphone1 .body,
  .iphone2 .body {
    width: 180.53px;
    height: 350.51px;
  }

  .iphone1,
  .iphone2 {
    left: 25%;
  }

  .bg-1,
  .bg-2,
  .bg-3,
  .bg-4,
  .bg-5,
  .bg-6,
  .bg-7,
  .bg-8,
  .bg-9,
  .bg-10 {
    display: none;
  }

  .footer {
    padding: 10px 10px;
    height: auto;
  }
}

@media (max-width: 900px) {
  .custom-container {
    padding: 0 10px;
  }
}

@media (max-width: 600px) {
  .iphone {
    display: flex;
    position: absolute;
    left: 25%;
    flex-direction: column;
  }
  .iphone .frame {
    position: absolute;
    width: 203.53px;
    height: 421.51px;
    z-index: 2;
  }

  .iphone .body {
    position: absolute;
    width: 203.53px;
    height: 421.51px;
    padding: 10.21px 12.02px 10.21px 12.37px;
    border-radius: 12px;
    overflow: hidden;
  }
  .title-partners {
    font-size: 32px;
  }
}
.w-xl-auto {
  width: auto !important;
}
h3 {
  font-size: 90px !important;
  text-transform: uppercase;
  font-weight: 800 !important;
  text-shadow: 1px 1px 1px #340040;
  width: 100%;
  margin: 0;
  /* height: 100%;
  
  text-align: center; */
}
.carousel-indicators [data-bs-target] {
  height: 10px !important;
  background-color: #340040 !important;
}

@media (max-width: 990px) {
  .w-xl-auto {
    width: 100% !important;
  }
  .main-title {
    font-size: 24px;
  }
  .sub-title {
    font-size: 16px;
  }
  li {
    font-size: 16px;
  }
  .btn-partners {
    /* margin: 40px 0px !important; */
  }
  h3 {
    font-size: 40px !important;
    text-transform: uppercase;
    font-weight: 800 !important;
    text-shadow: 5px #525252;
  }
}

.btn-partners {
  /* color: white; */
  /* background: #340040; */
  transition: 0.4s linear;
}
.btn-partners:hover {
  color: white;
  background: #340040;
  transition: 0.4s linear;
  /* font-weight: 400; */
}
.blanking-loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.blanking-loader {
  animation: rotate 1.5s linear infinite;
  width: 50px;
  height: 50px;
}

.blanking-loader-circle {
  fill: none;
  stroke: #333; /* Adjust color as needed */
  storke-width: 3;
  storke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}

@keyframes rotate {
  0% {
    opacity: 10%;
    /* transform: rotate(360deg); */
  }
  10% {
    opacity: 20%;
    /* transform: rotate(360deg); */
  }
  25% {
    opacity: 30%;
  }
  35% {
    opacity: 35%;
    /* transform: rotate(360deg); */
  }
  40% {
    opacity: 40%;
    /* transform: rotate(360deg); */
  }
  50% {
    opacity: 50%;
    /* transform: rotate(360deg); */
  }
  60% {
    opacity: 60%;
    /* transform: rotate(360deg); */
  }
  70% {
    opacity: 70%;
    /* transform: rotate(360deg); */
  }
  85% {
    opacity: 85%;
    /* transform: rotate(360deg); */
  }
  100% {
    opacity: 100%;
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
