$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.error {
  width: 100%;
  justify-content: center;
  height: 90vh;
  padding: 0px 32px 0px 32px;
  align-items: center;
  h1 {
    color: $textBlack;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  p {
    color: $textGray;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 187%;
    margin-bottom: 24px;
  }
}
