$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.voucher {
  .title {
    margin-bottom: 32px;
  }
  .table-option {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .main-section {
    margin-top: 32px;
    .options {
      margin-bottom: 32px;
    }
    .input {
      margin-bottom: 16px;

      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .input--options {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
        margin-top: 0;
      }
      .input--link {
        color: $textGray;
        margin-top: 8px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
        margin-top: 0;
      }

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin-bottom: 8px !important;
      }
      button {
        margin-top: 32px !important;
        margin-bottom: 32px !important;
      }
    }
  }
}

.supplier-option {
  padding: 8px !important;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 8px;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    // margin-inline-end: 16px;
  }

  .supplier-name {
    color: $mainColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }
  .supplier-category {
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.table-options {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 250% !important;
  text-overflow: unset;
  background: white !important;
  z-index: 5;
  position: absolute;
  //   top: 46px;
  //   border: 1px solid $mainColor;
  text-align: end;
  right: 12px;
  padding: 8px 16px !important;
  // justify-self: self-end !important;
  // align-self: flex-end;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  p {
    width: max-content;

    flex-grow: 1;
    display: flex;
    // font-weight: 500;
    margin-bottom: 18px !important;
    line-height: normal;
    // padding: 0;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }

  .title1 {
    color: $textBlack;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px !important;
  }
  .title2 {
    color: $textBlack;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px !important;
  }
  // padding: 16px;
}

.company-profile--data {
  padding: 0;
  //   padding-top: 24px;
  .single-item {
    // direction: auto;
    margin-bottom: 16px;
    padding: 0;
    .category-option {
      border: 2px solid $mainColor;
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 8px 20px;
      border-radius: 10px;
      margin: 0;
      margin-inline-end: 8px;
      //   margin-top: 16px !important;
    }
    p {
      padding: 0px;
      color: $mainColor;
      margin-bottom: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 255px;
      height: 150px;
      margin-inline-end: 8px;
    }

    span {
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.green {
        color: $mainColor;
        font-weight: 500;
      }
      &.icon {
        color: $textGray;
        svg {
          margin-inline-end: 8px;
          transition: 0.3s all;
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.supplier-option {
  padding: 8px !important;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 8px;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    // margin-inline-end: 16px;
  }

  .supplier-name {
    color: $mainColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }
  .supplier-category {
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.category-option1 {
  border: 2px solid $mainColor;
  color: $textGray;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 20px;
  border-radius: 10px;
  margin: 0;
  margin-inline-end: 8px;
}

.supplier-result {
  padding-top: 16px !important;
  height: auto;
  max-height: 350px !important;
  overflow-y: auto !important;
}

.header-option {
  border: 1px solid $mainColor !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}
.area {
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  //   width: auto;
  margin-inline-end: 8px;
  border: 1px solid $mainColor;
  color: $mainColor;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  //   margin-top: 16px !important;
}
