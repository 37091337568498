$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;

.request-page {
  .main-section {
    margin-top: 32px;
    .options {
      margin-bottom: 32px;
    }
    .pagination {
      margin: 24px 0 !important;
    }
    .table-option {
      margin-top: 32px !important;
      margin-bottom: 32px;
    }
    .input {
      margin-bottom: 16px;

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px !important;
      }
      .button {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
    .info {
      h1 {
        color: $mainColor;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // border: 1px solid red;
        span {
          color: $textBlack;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: end;
        }
      }
      img {
        width: 100%;
        height: 271px;
      }
    }
    .info-image {
      // display: inline;
      img {
        width: 184px;
        height: 123px;
        border-radius: 10px;
        margin-inline-end: 16px;
      }
    }
  }
  .title {
    margin-bottom: 24px;
  }
}

.table-options {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: max-content !important;
  text-overflow: unset;
  background: white !important;
  z-index: 5;
  position: absolute;
  //   top: 46px;
  border: 1px solid $mainColor;
  text-align: end;
  right: 12px;
  padding: 16px !important;
  // justify-self: self-end !important;
  // align-self: flex-end;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  p {
    width: max-content;
    flex-grow: 1;
    display: flex;
    margin-bottom: 16px !important;
    line-height: normal;
    // padding: 0;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  // padding: 16px;
}
