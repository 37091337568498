@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Parkinsans:wght@300..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=IBM+Plex+Sans+Arabic:wght@100;200;300;400;500;600;700&family=Parkinsans:wght@300..800&display=swap");
body {
  margin: 0;
  font-family: "Poppins", "IBM Plex Sans Arabic", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fcfcfc !important;
}

* {
  font-family: "Poppins", "IBM Plex Sans Arabic", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 3px;
  border-radius: 10px;
}
::-webkit-scrollbar:hover {
  width: 6px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  cursor: pointer;
  background: #134074;
  /* // height: 10px; */
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  width: 6px;
  cursor: pointer;
}

.PhoneInputInput {
  border: 0;
}
.PhoneInputInput:focus {
  border: 0;
  outline: 0;
}
input[type="radio"] {
  color: #134074 !important;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
}
.cursor:hover {
  cursor: pointer !important;
}

.br {
  border-bottom: 1px solid #d9d9d9;
  margin: 24px 0;
}

@media (min-width: 992.5px) {
  .w-lg-auto {
    width: auto !important;
  }
}

.suggestInput {
  /* border: 6px solid #134074 !important; */
}
/* .swiper {
  width: 600px !important;
  height: 300px;
} */
