$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;
$textDarkGray: #8c8c8c;
.options {
  padding: 0 !important;
  margin: 0 !important;
  // border: 1px solid red; // word-break: keep-all;
  // border: 1px solid red;
  .option {
    // text-wrap: pretty;
    word-break: keep-all;
    color: $textGray;
    font-size: 20px;
    // font-style: normal;
    font-weight: 500;
    // line-height: normal;
    justify-content: center;

    padding: 8px 16px 8px 16px;
    // margin: 0;
    // margin-bottom: 100px;
    // border: 1px solid red;
    padding-top: 4px !important;
    // margin-bottom: 30px !important;
    // border: 1px solid red;
    // margin-inline-end: ;
    &:hover {
      cursor: pointer;
    }
  }
  .selected-option {
    word-break: keep-all;
    color: $mainColor;
    background: $highlightBlue;
    border-radius: 10px;
    padding: 8px 16px;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
