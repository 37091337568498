$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;

.deal-card {
  border: 1px solid $mainColor;
  border-radius: 10px;
  margin-bottom: 28px;
  padding: 16px;
  .Accepted,
  .Rejected,
  .NeedReview,
  .DealReview {
    padding: 8px 20px;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 10px;
    text-align: center;
    justify-content: center;
    margin-bottom: 16px;
  }
  .Accepted {
    background: #e9fff0;
    color: #3eb655;
  }
  .DealReview {
    background: $highlightBlue;
    color: $mainColor;
  }
  .Rejected {
    background: #ffdede;
    color: red;
  }
  .NeedReview {
    background: $highlightBlue;
    color: $mainColor;
  }

  .single-item {
    // margin: 0 !important;
    margin-bottom: 8px !important;
    // border: 1px solid red;
    align-items: center;
    display: flex;
    padding: 0px;
    height: auto;
    p {
      color: $textBlack;
      // border: 1px solid red;
      align-self: center;
      margin-bottom: 0;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      // line-height: normal;
      padding-bottom: 0px;
    }
    // div {
    //   width: 30px !important;
    //   height: 30px !important;
    //   color: $maiGreen !important;
    //   fill: $maiGreen !important;
    //   background-color: $maiGreen !important;
    //   border-radius: 50% !important;
    // }
  }

  .single-item-progess1 {
    width: 30px !important;
    height: 30px !important;
    object-fit: fill;
    color: $maiGreen !important;
    fill: $maiGreen !important;
    background-color: $maiGreen !important;
    border-radius: 50% !important;
  }
  .category-option {
    border: 2px solid $highlightBlue;
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 20px;
    border-radius: 10px;
    margin: 0;
    margin-inline-end: 8px;
  }

  .category-option1 {
    border: 2px solid $mainColor;
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 20px;
    border-radius: 10px;
    margin: 0;
    margin-inline-end: 8px;
  }
}

.tooltip {
  position: relative;
  display: inline-block;
  // overflow-y: visible;
  // overflow-x: hidden;
  // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext {
  width: auto;
  bottom: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}

.container {
  // width: auto;
  overflow: hidden;
}

// p {
//   &.container {
//     display: block;
//     overflow: hidden;
//     padding: 0.5rem;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//   }
// }

p:hover {
  // &.container {
  // background-color: gray !important;
  // contain: paint;
  // display: inline-flex;
  // outline: 1px solid;

  overflow: visible !important;
  // width: auto;
  // min-width: 100%;
  // width: 100%;
  // position: rel/ative;
  // top: -25px;
  // }
}
