$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.sidebar {
  // padding:;
  // border: 1px solid red;
  height: 100vh;
  padding: 48px 16px;
  width: max-content;
  // padding-inline-start: 0 !important;
  // position: static;
  // height: 100%;
  // width: 100%;
  // width: 100%;
  // transition: all 0.6s linear;

  // transition: left 5s ease;
  border-right: 1.5px solid $borderColor;
  ul {
    // position: fixed;
    // width: max-content;
    padding-inline-start: 0px !important;
    transition: all 0.6s linear;
    justify-content: center;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // animation: reverse;
    list-style: none;
    // border: 1px solid red;
    li {
      // transition: all 0.6s linear;
      color: $mainColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 400 !important;
      line-height: normal;
      margin: 0;
      padding: 0;
      // border: 1px solid red;

      // align-items: center;
      // flex-direction: row;
      margin-bottom: 16px;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 8px !important;
      // border: 1px solid red;
      width: auto;
      // flex-direction: row;

      .item {
        padding: 8px 8px 8px 8px;

        // transform: translateX(-8px);
      }

      &:hover,
      &.active {
        .item {
          // padding: 8px !important;
          color: $textBlack;

          &:first-child {
            // border: 1px solid yellow;
            transition: 0.3s all;
            padding: 8px 16px 8px 16px !important;
            background: $highlightBlue;
            // border: 1px solid red;
            display: flex;
            align-items: center;
            border-radius: 10px;
            transform: translateX(-8px);
          }
        }
      }

      &.logo {
        height: auto;
        margin-bottom: 32px;
        justify-self: center;
        // border: 1px solid red;
        transition: all 0.6 linear;
      }
      &#back-button {
        transform: translateX(36px);
        height: auto;
        z-index: 5;
        // background: white;
        margin: 0;
        // height: 0;
        // height: auto;
        margin-bottom: 22px;
        &:hover {
          cursor: pointer;
        }
      }
    }
    // li
    // }
  }
}
// .sidebar-collapse {
//   height: 100vh;
//   padding: 48px 16px;
//   //   border: 1px solid orange;
//   width: max-content;
//   text-align: start;
//   border-right: 1.5px solid $maiGreen;
//   ul {
//     transition: all 0.6s linear;

//     list-style: none;
//     justify-content: start;
//     // border: 1px solid green;
//     display: flex;
//     flex-direction: column;
//     padding: 0;

//     margin: 0;
//     li {
//       transition: width 0.6s ease-out;

//       color: $mainColor;
//       font-size: 14px;
//       font-style: normal;
//       font-weight: 400;
//       line-height: normal;
//       margin: 0;
//       padding: 0;
//       margin-bottom: 16px;
//       justify-content: center;
//       //   border: 1px solid red;
//       height: 40px;
//       display: flex;
//       align-items: center;
//       //   padding: 8px !important;
//       width: auto;

//       &:hover {
//         div {
//           padding: 8px !important;
//           background: $higlightBlue;
//           display: flex;
//           align-items: center;
//           border-radius: 10px;
//           // transform: translateX(-8px);
//         }
//       }

//       &.logo {
//         height: auto;
//         margin-bottom: 32px;
//         transition: all 0.6 linear;
//       }
//       &#back-button {
//         // transform: rotate(-90deg);
//         transform: translateX(28px);
//         height: auto;
//         z-index: 5;
//         background: white;
//         margin: 0;
//         height: auto;
//         margin-bottom: 22px;
//       }
//       &:hover {
//         cursor: pointer;
//       }
//     }
//     // li
//     // }
//   }
// }

.active {
  padding: 8px 16px 8px 16px !important;
  background: $highlightBlue !important;
  display: flex;
  align-items: center;
  border-radius: 10px;
  color: $textBlack;
  transform: translateX(-8px) !important;
}

.option {
  font-weight: 500 !important;
}
