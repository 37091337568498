$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$bacgroundWhite: #ffffff;
$mainColor: #134074;
$textLightGray: #d9d9d9;

$borderColor: #949494;
$highlightBlue: #91c8e4;

.green {
  color: $maiGreen;
  &:hover {
    cursor: pointer;
  }
}

.phone {
  height: 45px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $textGray;
  &::placeholder {
    color: $textGray;
    // border: 1px solid red;
    position: relative;
    // top: -5px;
    font-size: 14px;
    font-weight: 300;
  }
  &:active,
  &:focus {
    border: 1px solid $mainColor;
    outline: 1px solid $mainColor;
  }

  &.error {
    border: 1px solid red;
    &:active,
    &:focus {
      border: 1px solid red;
      outline: 1px solid red;
    }
  }
}

span {
  color: $textGray;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0;
}

.gray {
  color: $mainColor;
  font-weight: 500;
}

.underLineText {
  color: $borderColor !important;
  font-size: 12px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
  margin-top: 8px !important;
}

.upload-images-infint {
  padding: 8px;
  border: 1px solid $mainColor;
  border-radius: 10px;
  h1 {
    color: $mainColor;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px;
  }
  p {
    color: $textGray;
    // font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    span {
      color: $textGray !important;
      // font-family: Inter;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 300 !important;
      line-height: normal;
      text-decoration: underline;
      &:hover {
        cursor: pointer;
        color: $textBlack !important;
        transition: all 0.3s;
      }
    }
  }
  .input-feild-image {
    position: relative;
    width: 181px !important;
    height: 161px;
    display: flex;
    background: transparent;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    display: flex;

    transition: all 0.5s;
    border: 1.5px dotted $textGray;

    img {
      width: 181px;
      height: 161px;
      object-fit: cover;
    }
    &:hover {
      cursor: pointer;
      background: $textLightGray;
    }

    p {
      color: $textGray;
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
  }
  .close-btn {
    border-radius: 50%;
    &:hover {
      cursor: pointer;
    }
  }
}
span {
  &:hover {
    cursor: pointer !important;
  }
  input {
    opacity: 0;
  }
}

.upload-images-infinte-error {
  border: 2px solid red !important;
}

.drag {
  width: auto !important;
  min-width: auto !important;
  max-width: auto !important;
  height: 100%;
  // border: 1px solid red;
  // opacity: 0;
}

// .upload-images-infint:-moz-drag-over .drag {
//   opacity: 1;
// }
