$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;
.Page-title {
  // width: auto;
  // overflow: hidden;
  color: $mainColor;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  // padding: 0 32px !important;
}

