$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.table-options {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 250% !important;

  text-overflow: unset;
  background: white !important;
  z-index: 5;
  position: absolute;
  text-align: end;
  right: 12px;
  padding: 8px 16px !important;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  p {
    width: max-content;
    flex-grow: 1;
    display: flex;
    line-height: normal;
    padding-inline-end: 50px !important;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  .title1 {
    color: $textBlack;
    font-size: 15px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 16px !important;
  }
  .title2 {
    color: $textBlack;
    font-size: 20px !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding-inline-end: 50px !important;

    // margin-bottom: 8px !important;
  }
  // padding: 16px;
}

.one-line {
  //   width: 100%;
  //   width: 250px !important;
  max-width: 300px;
  span {
    color: $textBlack !important;
  }
  //   padding: 0;
  //   display: inline !important; /* important */
  //   white-space: nowrap;
  //   overflow: hidden;
  //   word-break: break-all;
  /* when not hovering show ellipsis */
  &:not(:hover) {
    text-overflow: ellipsis;
  }

  /* animate on either hover or focus */
  &:hover,
  &:focus {
    span {
      //   padding: 0;
      display: inline-block;
      color: $textBlack !important;
      animation-name: scroll-text;
      animation-duration: 7s;
      animation-timing-function: linear;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      /* FYI this would be the shorthand:
          animation: scroll-text 5s ease 0s 2 normal;
        */
    }
  }

  /* define the animation */
  @keyframes scroll-text {
    0% {
      transform: translateX(0%);
    }
    90% {
      transform: translateX(-100%);
    }
    95% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(0%);
    }
  }
}
