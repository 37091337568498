$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;
$textDarkGray: #8c8c8c;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.table1 {
  overflow: scroll;
  min-height: 300px;
}
.table {
  background: transparent;
  width: 100% !important;
  thead {
    tr {
      padding-top: 0;
      border-bottom: 1px solid $textLightGray;
      th {
        text-align: center;
        position: relative;
        padding: 8px 8px !important;
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        span {
          margin-inline-start: 8px;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  tbody {
    tr {
      margin-bottom: 8px;
      th {
        text-align: center;
        color: $textBlack;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 8px;
        padding-top: 16px;
        img {
          width: 50px;
          height: 50px;
          border-radius: 10px;
          object-fit: cover;
        }
      }
    }
  }
}

.options {
  width: max-content;
  z-index: 50000;
  background: white;
  position: absolute;
  top: 46px;
  padding: 10px;
  border-radius: 10px;
  animation: slide-down 300ms ease-out forwards;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  label {
    display: inline;
    width: auto;
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: all 0.3s;

    &:hover {
      cursor: pointer;
      font-weight: 500;
    }
  }
}

@media (max-width: 1000px) {
  .table {
    max-width: 1000px !important;
    min-width: 1000px !important;
  }
}

::-webkit-scrollbar {
  height: 5px;
  border-radius: 10px;
}
::-webkit-scrollbar:hover {
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: $textGray;
  height: 5px;
}
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
}

.selected {
  padding: 8px;
  // display: inline;
  border-radius: 70px;
  background: #ff8ab5;
  margin-bottom: 4px;
  padding: 16px !important;
  position: relative;
  top: 5px;
  transform: scale(0.9);
  justify-self: center;

  margin-inline-start: 8px !important;
}
.selected1 {
  // display: flex;
  // display: inline;
  padding: 8px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  background: #ff8ab5;
  // border: 1px solid transparent !important;
}

.pagination {
  margin: 24px 0 !important;
}

.selected-supplier {
  padding-bottom: 8px !important;

  background: #e3e3e333;
  border-radius: 30px !important;
}
