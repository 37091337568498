$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.single-item {
  display: flex;
  width: max-content;
  // justify-content: center;
  flex-direction: column;
  position: relative;
  align-items: center;
  img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 16px;
  }
  h1 {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    line-height: normal;
  }
  p {
    color: $maiGreen;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    line-height: normal;
  }
  span {
    color: $textGray;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.filter {
  // width: auto;
  background: white;
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  p {
    color: $textBlack;
    margin-inline-start: 8px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}
.backdrop {
  position: fixed;
  top: 0;
  /* scroll-behavior: unset; */
  /* overflow: hidden; */
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  //   background-color: rgba(0, 0, 0, 0.75);
}

.header {
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-inline-end: 32px;
  }
  h1 {
    color: $mainColor;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0;
    margin-inline-end: 16px !important;
  }
  .category-option {
    border: 2px solid $mainColor;
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 8px 20px;
    border-radius: 10px;
    margin: 0;
    margin-inline-end: 8px;
  }
}

.options-filter {
  background: white;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  padding: 16px;
  line-height: normal;
  color: $textBlack;
  width: fit-content;
  right: 0px;
  width: max-content;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

  &:hover {
    cursor: pointer;
  }
}

.Rejected {
  color: #f00;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  border-radius: 10px;
  background: #ffdede;
  margin-bottom: 0;
  padding: 8px 20px !important;
  align-items: center;
}
.Review {
  color: $mainColor;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  border-radius: 10px;
  background: rgba(26, 133, 238, 0.21);
  margin-bottom: 0;
  padding: 8px 20px !important;

  align-items: center;
}
.Accepted {
  color: #3eb655;

  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  height: 100%;
  border-radius: 10px;
  background: #e9fff0;
  margin-bottom: 0;
  padding: 8px 20px !important;

  align-items: center;
}
