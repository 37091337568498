$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.share-deal-navbar {
  height: 65px;
  padding: 16px;
  position: sticky;
  top: 0px;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  p {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 400;
    text-align: center;
    line-height: 33px;
    color: #340040;
  }
  @media (max-width: 768px) {
    display: hidden !important;
  }
}

.share-deal-header {
  .share-deal-header-details {
    padding: 16px 32px;
    h1 {
      font-family: Poppins;
      font-size: 24px;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.3755354881286621px;
      text-align: left;
      color: #340040;
      margin-bottom: 8px;
    }
    p {
      color: #340040;
      font-family: Poppins;
      font-size: 18px;
      font-weight: 400;
      line-height: 27px;
      text-align: left;
      margin-bottom: 8px;
    }
    span {
      //   margin-bottom: 8px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;

      color: #340040;
    }
    .countdown {
      font-size: 20px;
      font-weight: 600;
      line-height: 20.25px;
      letter-spacing: 3px;
      // padding: 2px;
      text-align: center;
      color: #407bff;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .countdown-text {
      font-size: 16px;
      // padding: 0px 10px;
      font-weight: 300;
      line-height: 20.25px;
      letter-spacing: -0.3755354881286621px;
      color: #340040;
      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
.wholeOptions {
  //   border: 1px solid red;
  //   margin-top: 16px;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  .options {
    padding: 0px 32px 0px 32px;
    @media (max-width: 768px) {
      padding: 0px 10px 0px 10px;
    }
    .option {
      padding: 0px 32px 0px 32px;
      color: #9b84a4;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      @media (max-width: 768px) {
        padding: 0px 15px 0px 15px;
      }
      &:hover {
        cursor: pointer;
      }
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
    .selected-option {
      color: #340040;
      // text-decoration: underline;
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      border-bottom: 4px solid #340040;
      @media (max-width: 768px) {
        font-size: 18px;
      }
    }
  }
}
.deal-data-1 {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 300;
  line-height: 20.25px;
  letter-spacing: -0.3755354881286621px;
  text-align: center;
  color: #340040;
}
.deal-data-2 {
  font-family: Poppins;
  font-size: 18px;
  font-weight: 400;
  line-height: 20.25px;
  letter-spacing: -0.3755354881286621px;
  text-align: center;

  border-left: 1.5px solid #340040;
  border-right: 1.5px solid #340040;

  color: #340040;
}

.progress2 {
  width: 50% !important;
  height: 25px;
  // top: 25px;
  // left: 1191px;
  // gap: 0px;
  border-radius: 15px 0px 0px 0px;
  // opacity: 0px;
  @media (max-width: 768px) {
    width: 80% !important;
  }
}
.progress1 {
  // width: 50%;

  position: absolute;
  height: 25px;
  // top: 25px;
  left: 0px;
  // gap: 0px;
  border-radius: 15px 0px 0px 0px;
  // opacity: 0px;
  background: #f2055c;
}

.progress-stats {
  width: 50% !important;
  display: block;
  margin-top: 8px !important;
  margin-bottom: 16px !important;
  @media (max-width: 768px) {
    width: 80% !important;
  }

  p {
    display: inline;
    width: auto;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 400;
    line-height: 20.25px;
    letter-spacing: -0.3755354881286621px;
    // text-align: center;
    color: #340040;
  }
}

.mobile-navbar {
  background: white;
  position: sticky;
  bottom: 0px;
  height: 87px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 15px !important;
}
.border {
  width: 350px !important;
  height: 1px;

  // border: 1px solid;
  background: linear-gradient(
    270deg,
    rgba(52, 0, 64, 0.3) 0.7%,
    #340040 47.69%,
    rgba(52, 0, 64, 0.3) 99.48%
  );
  margin: 16px 0px !important;
  @media (max-width: 768px) {
    width: 250px !important;
  }
  // border-image-source: linear-gradient(270deg, rgba(52, 0, 64, 0.3) 0.7%, #340040 47.69%, rgba(52, 0, 64, 0.3) 99.48%);
}

.supplier-data {
  h1 {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
    color: #340040;
    margin-bottom: 12px;
  }
  p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 8px;
    // line-height: 27px;
    text-align: left;
    color: #340040;
  }
  span {
    font-family: Poppins;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    // line-height: 21px;
    text-align: left;
    color: #34004080;
  }
}

.main-section {
  padding: 0 32px;
  @media (max-width: 768px) {
    padding: 0 5px;
  }
}

.deal-desc {
  h1 {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #340040;
  }
  p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
    color: #6d6270;
  }
}

.product-data {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  span {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    // text-align: left;
    color: #6d6270;
    margin-bottom: 24px !important;
  }
  p {
    align-items: center;
    margin-inline-start: 5px;
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    // line-height: 21px;
    // text-align: center;
    color: #340040;
  }
}
.location-data {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    color: #340040;
  }
  p {
    padding: 8px 20px;
    width: auto;

    display: inline;
    border-radius: 8px;
    margin: 0px 10px 10px 0px;
    background: #34004080;
    color: #340040;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
  }
}
