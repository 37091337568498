$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.cold-deals {
  .title {
    margin-bottom: 32px;
  }
  .table-option {
    margin-top: 32px !important;
    margin-bottom: 32px !important;
  }
  .main-section {
    margin-top: 32px;
    .options {
      margin-bottom: 32px;
    }
    .input {
      margin-bottom: 16px;

      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        &:hover {
          cursor: pointer;
        }
      }

      .input--options {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px !important;
        // border: 1px solid red;
        margin-top: 0;
      }
      .input--link {
        color: $textGray;
        margin-top: 8px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
        margin-top: 0;
      }

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin-bottom: 8px !important;
      }
      button {
        margin-top: 32px !important;
        margin-bottom: 32px !important;
      }
    }
    .input1 {
      // margin-bottom: 16px;
      padding: 0;

      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        // margin-bottom: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .input--options {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px !important;
        // border: 1px solid red;
        margin-top: 0;
      }
      .input--link {
        color: $textGray;
        margin-top: 8px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 0px;
        margin-top: 0;
      }

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // margin-bottom: 8px !important;
      }
      button {
        // margin-top: 16px !important;
        margin-bottom: 24px !important;
      }
    }
  }
}

.supplier-option {
  padding: 8px !important;
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 8px;
  img {
    border-radius: 50%;
    width: 80px;
    height: 80px;
    // margin-inline-end: 16px;
  }

  .supplier-name {
    color: $mainColor;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }
  .supplier-category {
    color: $textGray;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.deal-card {
  border: 1px solid $mainColor;
  border-radius: 10px;
  padding: 16px;
  .single-item {
    p {
      color: $textBlack;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.company-profile--data {
  padding: 0;
  //   padding-top: 24px;
  .single-item {
    // direction: auto;
    margin-bottom: 16px;
    padding: 0;
    .category-option {
      border: 2px solid $mainColor;
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 8px 20px;
      border-radius: 10px;
      margin: 0;
      margin-inline-end: 8px;
      //   margin-top: 16px !important;
    }
    p {
      padding: 0px;
      color: $mainColor;
      margin-bottom: 8px;
      font-size: 18px;
      margin-inline-end: 24px;
      font-style: normal;
      display: inline;
      font-weight: 500;
      line-height: normal;
      width: auto;
    }
    img {
      width: 255px;
      height: 150px;
      margin-inline-end: 8px;
    }

    span {
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      width: auto;
      display: inline;
      line-height: normal;

      &.green {
        color: $mainColor;
        font-weight: 500;
      }
      &.icon {
        color: $textGray;
        svg {
          margin-inline-end: 8px;
          transition: 0.3s all;
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}

.quantities-item {
  border: 1px solid $mainColor;
  color: $mainColor;
  padding: 8px 8px 8px 12px;
  border-radius: 54px;
  margin-inline-end: 8px;
}

.branch-card {
  border: 2px solid $mainColor;
  padding: 16px;
  border-radius: 10px;
  h1 {
    color: $mainColor;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    color: $mainColor;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  margin-bottom: 27px;
}

.header-option {
  border: 1px solid $mainColor !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}
.title {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.area {
  display: flex;
  padding: 8px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  //   width: auto;
  margin-inline-end: 8px;
  border: 1px solid $mainColor;
  color: $mainColor;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  //   margin-top: 16px !important;
}

.green {
  p,
  span {
    color: $maiGreen !important;
  }
}

.header-option {
  border: 1px solid $mainColor !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}

.title {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.options-filter {
  background: white;
  //   width: auto;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  padding: 16px;
  //   border: 1px solid gray;
  line-height: normal;
  color: $textBlack;
  //   width: 100%;
  width: fit-content;
  //   left: 0px;
  right: 0px;
  // width: 232px;
  width: max-content;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

  //   overflow: ;
  &:hover {
    cursor: pointer;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  /* scroll-behavior: unset; */
  /* overflow: hidden; */
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  //   background-color: rgba(0, 0, 0, 0.75);
}

.header-option {
  border: 1px solid $mainColor !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 6px;
}

.title {
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.options-filter {
  background: white;
  //   width: auto;
  border-radius: 10px;
  font-size: 14px;
  position: absolute;
  font-style: normal;
  font-weight: 400;
  padding: 16px;
  //   border: 1px solid gray;
  line-height: normal;
  color: $textBlack;

  //   left: 0px;
  right: 0px;
  // width: 232px;
  width: max-content;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);

  //   overflow: ;
  &:hover {
    cursor: pointer;
  }
}
