$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;

.about-page {
  .main-section {
    margin-top: 32px;
    .options {
      margin-bottom: 32px;
    }
  }
}
