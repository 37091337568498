$higlightGreen: #e9fff0;
$maiGreen: #134074;
$textGray: #949494;
$textBlack: #151816;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.about {
  h1 {
    color: $textBlack;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
  }
  .options {
    margin: 24px 0;
  }

  .link {
    color: $maiGreen;
    // text-decoration: un;
    width: fit-content !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      cursor: pointer;
    }
  }
  .input {
    //   margin-bottom: 0;
    //   padding-bottom: 0;
    //   margin-bottom: 16px;
    //   border: 1px solid red;
    //   &:first-of-type {
    //     padding-top: 32px;
    //   }
    margin-bottom: 16px;

    .button {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

.edit-company-profile {
  h1 {
    color: $textBlack;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
  }
  .options {
    margin: 24px 0;
  }

  .link {
    color: $maiGreen;
    // text-decoration: un;
    width: fit-content !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      cursor: pointer;
    }
  }
  .input {
    //   margin-bottom: 0;
    //   padding-bottom: 0;
    //   margin-bottom: 16px;
    //   border: 1px solid red;
    //   &:first-of-type {
    //     padding-top: 32px;
    //   }
    margin-bottom: 16px;

    .button {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}
.edit-company-profile {
  h1 {
    color: $textBlack;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
  }
  .options {
    margin: 24px 0;
  }

  .link {
    color: $maiGreen;
    width: fit-content !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      cursor: pointer;
    }
  }
  .input {
    margin-bottom: 16px;

    .button {
      margin-top: 32px;
      margin-bottom: 32px;
    }
  }
}

.company-profile--member {
  padding: 0;
  padding-top: 24px;

  .single-item {
    display: flex;
    width: max-content;
    // justify-content: center;
    flex-direction: column;
    position: relative;
    align-items: center;
    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 16px;
    }
    h1 {
      color: $textBlack;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 4px;
      line-height: normal;
    }
    p {
      color: $maiGreen;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      margin-bottom: 4px;
      line-height: normal;
    }
    span {
      color: $textGray;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.close-btn {
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
}

.error {
  color: red;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 8px 0;
}
