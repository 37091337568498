$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$bacgroundWhite: #ffffff;

.pop {
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid $maiGreen;

  p {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 0px 8px;
    padding-inline-end: 40px;
  }
  svg {
    margin: 2px -8px;
  }
  span {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    padding: 0px 8px;
  }
}
