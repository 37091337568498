$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.managers {
  .title {
    margin-bottom: 32px;
  }
  .input {
    //   margin-bottom: 0;
    //   padding-bottom: 0;
    //   margin-bottom: 16px;
    //   border: 1px solid red;
    //   &:first-of-type {
    //     padding-top: 32px;
    //   }

    margin-bottom: 16px;
  }
  .pagination {
    margin: 24px 0 !important;
  }
  .main-section {
    // margin-top: 32px !important;
    // padding-top: 32px !important;
    .table-option {
      margin-top: 32px !important;
    }
    .options {
      margin-bottom: 32px;
    }
    .manager-edit-left {
      padding: 16px !important;
      background: $highlightlightBlue;
      border-radius: 10px;
      height: fit-content;
      .options {
        margin-bottom: 0px;
      }
      img {
        width: 100%;
        height: 200px;
        object-fit: cover;
      }
      p {
        color: $textBlack;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        text-decoration-line: underline;
        margin-top: 16px;
      }
    }
    .manager-edit-right {
      padding: 16px !important;
      background: $highlightlightBlue;
      border-radius: 10px;
      height: auto;

      .input {
        //   margin-bottom: 0;
        //   padding-bottom: 0;
        //   margin-bottom: 16px;
        //   border: 1px solid red;
        //   &:first-of-type {
        //     padding-top: 32px;
        //   }

        margin-bottom: 16px;
      }
      p {
        color: $textBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      h1 {
        color: $mainColor;
        font-size: 22px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 16px;
      }
    }
  }
}

.company-profile--data {
  padding: 0;
  //   padding-top: 24px;
  .single-item {
    // direction: auto;
    margin-bottom: 16px;
    padding: 0;
    .category-option {
      border: 2px solid $mainColor;
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 8px 20px;
      border-radius: 10px;
      margin: 0;
      margin-inline-end: 8px;
      //   margin-top: 16px !important;
    }
    p {
      padding: 0px;
      color: $mainColor;
      margin-bottom: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: auto;
      // width: 255px;
      height: 150px;
      margin-inline-end: 8px;
      object-fit: scale-down;
    }

    span {
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.green {
        color: $mainColor;
        font-weight: 500;
      }
      &.icon {
        color: $textGray;
        svg {
          margin-inline-end: 8px;
          transition: 0.3s all;
          &:hover {
            cursor: pointer;
            transform: scale(1.2);
          }
        }
      }
    }
  }
}
