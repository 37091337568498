$higlightGreen: #e9fff0;
$mainColor: #26cd61;
$textGray: #949494;
$darkGray: #504e4e;
$textBlack: #151816;
$bacgroundWhite: #ffffff;
$textLightGray: #d9d9d9;

$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.search {
  input {
    border: 1px solid $textGray;
    padding-inline-start: 10px;
    color: $darkGray;
    &::placeholder {
      color: $textGray;
      position: relative;
      font-size: 14px;
      font-weight: 300;
    }
    &.error {
      border: 1px solid red;
      outline: 1px solid red;
      &:active,
      &:focus {
        border: 1px solid red;
        outline: 1px solid red;
      }
    }
  }
}
.main-color {
  color: $mainColor;
  &:hover {
    cursor: pointer;
  }
}
