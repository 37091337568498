.nav-bar {
  background: #340040 !important;
  /* padding: 0 100px; */
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  /* height: 90px; */
  /* margin: 0; */
}

.nav-link,
.nav-link1 {
  font-family: "poppin";
  color: white !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  letter-spacing: 0em !important;
  text-align: left !important;
  border-radius: 30px;
  width: 150px;
  text-align: center !important;
}
.active,
.nav-link:hover {
  font-family: "poppin";
  text-align: center !important;
  /* border-bottom: 1px solid white; */
  color: #340040 !important;
  background: white !important;
  /* height: 100% !important; */
  padding: 0;
  border-radius: 30px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 700 !important;
  /* font-size: 18px !important; */
  /* font-weight: 500 !important; */
  /* line-height: 27px !important; */
  /* letter-spacing: 0em !important; */
  /* text-align: left !important; */
  /* width: 150px; */
}

@media (max-width: 992px) {
  .nav-link,
  .nav-link1 {
    width: 100% !important;
    display: block;
    margin-bottom: 16px;
    /* border: 1px solid wheat !important; */
  }
  .nav-link:hover,
  .active {
    width: 100% !important;
    padding: 8px;
    background: white !important;
  }
}

#basic-navbar-nav {
  display: block;
  padding: 0;
  /* border: 1px solid white; */
  width: 100%;
}

.navbar-toggler-icon {
  color: white !important;
  background: white;
}

.nav-bar {
  transition: transform 0.3s ease-in-out;
}

.collapsed {
  transform: translateY(-100%);
}

.navbar-collapse {
  transition: all 0.3s ease-in-out;
  opacity: 1; /* Ensure full visibility when expanded */
}

.collapsing {
  opacity: 0; /* Avoid abrupt display when collapsing */
}

.navbar-collapse {
  visibility: hidden;
  height: 0;
  transition: visibility 0s linear 0.3s, height 0.3s ease-in-out;
}

.navbar-collapse.show {
  visibility: visible;
  height: auto;
  transition-delay: 0s;
}
