$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$bacgroundWhite: #ffffff;
$textLightGray: #d9d9d9;
// $higlightGreen: #e9fff0;
// $maiGreen: #26cd61;
// $textGray: #949494;
// $textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.select-feild,
.input-feild {
  // border: 1px solid red;
  height: 45px;
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  border: 1px solid $mainColor;
  background: transparent;
  margin-bottom: 0 !important;
  &::placeholder {
    color: $textGray;
    // border: 1px solid red;
    position: relative;
    // top: -5px;
    font-size: 14px;
    font-weight: 300;
  }
  &:active,
  &:focus {
    border: 1px solid $mainColor;
    outline: 1px solid $mainColor;
  }

  &.error {
    border: 1px solid red;
    outline: 1px solid red;
    &:active,
    &:focus {
      border: 1px solid red;
      outline: 1px solid red;
    }
  }
}

.select-feild-filter {
  height: 40px;
  width: 100%;
  padding: 10px;
  font-size: 15px;
  color: $mainColor;
  border-radius: 8px;
  border: 1px solid $mainColor;
  background: transparent;
  padding-inline-start: 35px !important;
  &::placeholder {
    // padding-inline-start: 25px;
    color: $textGray;
    // border: 1px solid red;
    position: relative;
    // top: -5px;
    font-size: 14px;
    font-weight: 300;
  }
  &:active,
  &:focus {
    border: 1px solid $mainColor;
    outline: 1px solid $mainColor;
  }

  &.error {
    border: 1px solid red;
    outline: 1px solid red;
    &:active,
    &:focus {
      border: 1px solid red;
      outline: 1px solid red;
    }
  }
}
.eye {
  &:hover {
    cursor: pointer;
  }
}
img {
  padding: 0 !important;
  margin: 0;
  width: 181px;
  overflow: hidden;
  border-radius: 10px;
  height: 161px;
  // border: 1px solid red;
  object-fit: cover;
}

.input-feild-image {
  position: relative;
  width: 181px !important;
  height: 161px;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  display: flex;

  transition: all 0.5s;
  border: 1.5px solid $borderColor;

  img {
    width: 181px;
    height: 161px;
    object-fit: cover;
  }
  &:hover {
    cursor: pointer;
    background: $textLightGray;
  }

  p {
    color: $textGray;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }
}
.upload {
  // border: 1px solid yellow;
  // position: absolute;
  left: 0px !important;
  top: 0px !important;
  input {
    // height: 180px;
    width: 100%;
    position: absolute;
    height: 161px;
    opacity: 0;
    // border: 1px solid red;
    &:hover {
      cursor: pointer !important;
    }
  }

  &:hover {
    cursor: pointer !important;
  }
}

.close-btn {
  border-radius: 50%;
  &:hover {
    cursor: pointer;
  }
}

.search {
  height: 40px !important;
  input {
    // border-color: ;
    height: 40px !important;
    border: 1px solid $mainColor;
    border-radius: 10px;
  }
}

select {
  width: 10%;
  height: 50px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding-left: 5px;
  padding: 10px;
  font-size: 18px;
  font-family: 'Open Sans', sans-serif;
  color: #555;
  background-color: rgb(255, 255, 255);
  background-image: none;
  border: 1px solid rgb(41, 18, 18);
  border-radius: 10px !important;
  transition: 0.3s ease all;

  &.error {
    border: 1px solid red;
    outline: 1px solid red;
    &:active,
    &:focus {
      border: 1px solid red;
      outline: 1px solid red;
    }
  }
}
select > option {
  font-size: 18px;
  border-radius: 10px !important;
  transition: 0.3s ease all;

  // font-family: 'Open Sans', sans-serif;
  color: $mainColor;
  font-weight: 500;
  background-color: rgb(247, 247, 247);
  background-image: none;
  font-size: 18px;
  height: 50px;
  padding: 15px;

  border: 1px solid rgb(41, 18, 18);

  &::selection {
    background: $higlightGreen !important;
  }
}
select > option::-webkit-input-placeholder {
  font-size: 18px;
  border-radius: 10px !important;
  transition: 0.3s ease all;

  // font-family: 'Open Sans', sans-serif;
  color: $mainColor;
  font-weight: 500;
  background-color: rgb(247, 247, 247);
  background-image: none;
  font-size: 18px;
  height: 50px;
  padding: 15px;

  border: 1px solid rgb(41, 18, 18);

  &::selection {
    background: $higlightGreen !important;
  }
}

// select>option::
input[type='file'] {
  opacity: 0;
  border: 1px solid $mainColor;
}

.image-error {
  border: 3px solid red !important;
}

.selected {
  color: red !important;
}

// .padding {
//   // padding-inline-start: 10px !important;
//   &::placeholder {
//     padding-inline-start: 10px !important;
//     // border: 1px solid red;
//   }
// }
