$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;
$textDarkGray: #8c8c8c;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.branch-card {
  border: 2px solid $mainColor;
  padding: 16px;
  border-radius: 10px;
  h1 {
    color: $mainColor;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  p {
    color: $mainColor;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  margin-bottom: 27px;
}
