$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;

$bacgroundWhite: #ffffff;

.section {
  margin-bottom: 16px;

  h1 {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    // text-align: center;
    margin-bottom: 8px;
  }
  p {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .border {
    border: 1px solid $mainColor;
    border-radius: 8px;
    p {
      color: $textGray !important;
      padding: 0 !important;
    }
    padding: 12px 12px 0px 12px;
  }
}
