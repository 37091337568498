$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.form-page {
  .title {
    margin-bottom: 32px;
  }
  .input {
    margin-bottom: 16px;
  }
}
