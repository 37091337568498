$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$bacgroundWhite: #ffffff;

.input1 {
  padding: 0;

  p {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    &:hover {
      cursor: pointer;
    }
  }
  .input--options {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px !important;
    margin-top: 0;
  }
  .input--link {
    color: $textGray;
    margin-top: 8px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 0px;
    margin-top: 0;
  }

  span {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  button {
    margin-bottom: 24px !important;
  }
}
