$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.nav {
  // position: sticky;
  // top: 0;

  padding: 0 !important;
  margin: 0 !important;
  align-items: center !important;
  // display: flex;
  height: max-content;
  // margin-top: 32px;
  height: max-content !important;
  border-bottom: 1px solid $borderColor;
  padding: 32px !important;
  margin-bottom: 32px !important;
  // background: $bacgroundWhite;
  // box-shadow: 3px 8px 10px rgba(0, 0, 0, 0.06);

  h1 {
    margin: 0 !important;
    padding: 0 !important;
    color: $mainColor !important;

    font-size: 16px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    // line-height: 0 !important;
  }
}

.options {
  width: max-content;
  z-index: 50000;

  right: 0;
  // display: block;
  background: white;
  position: absolute;
  top: 46px;
  border: 1px solid $mainColor;
  padding-bottom: 0 px !important;
  padding: 16px !important;
  border-radius: 10px;
  animation: slide-down 300ms ease-out forwards;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
  // animation: slide-down 300ms ease-out forwards;
  // border: 1px solid red;
  p {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    // line-height: normal;
    // border: 1px solid red;
    align-items: center;
    align-self: center;
    justify-self: center;
    transition: all 0.3s;
    &:hover {
      cursor: pointer;
      font-weight: 500;
    }
  }
}

.backdrop {
  position: fixed;
  top: 0;
  /* scroll-behavior: unset; */
  /* overflow: hidden; */
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 20;
  //   background-color: rgba(0, 0, 0, 0.75);
}

@keyframes slide-down {
  from {
    opacity: 0;
    // height: 100%;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    // height: 50%;
    transform: translateY(0);
  }
}

.active {
  background: $higlightGreen;
  // width: 30px !important;
  height: 30px !important;
  padding: 4px 4px !important;
  padding-bottom: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
