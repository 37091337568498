$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;
.ad-card {
  padding: 16px;
  border: 1px solid $mainColor;
  border-radius: 10px;
  // height: 100%;
  img {
    width: 100%;
    height: 123px;
    object-fit: cover;
    margin-bottom: 16px;
  }
  h1 {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
  }
  p {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}
