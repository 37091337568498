$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$bacgroundWhite: #ffffff;

.title {
  color: $mainColor;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}

.input {
  //   margin-bottom: 0;
  //   padding-bottom: 0;
  //   margin-bottom: 16px;
  //   border: 1px solid red;
  //   &:first-of-type {
  //     padding-top: 32px;
  //   }
  margin-bottom: 16px;

  span {
    color: $textBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px !important;
  }
  .button {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.input {
  margin-bottom: 16px;

  p {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
    }
  }
  .input--options {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    margin-top: 0;
  }
  .input--link {
    color: $textGray;
    margin-top: 8px !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    margin-top: 0;
  }

  span {
    color: $mainColor;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px !important;
  }
  .button {
    margin-top: 32px;
    margin-bottom: 32px;
  }
}

.remove {
  p {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-top: 32px;
    margin-bottom: 16px;
    line-height: normal;
  }
  span {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 32px;
    line-height: normal;
  }
  .button {
    // &:first {
    margin-inline-end: 16px;
    // }
  }
}

.notification-details {
  p {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: auto;
    display: inline;
    // border: 1px solid red;
    margin-bottom: 16px !important;
  }
  span {
    color: $textBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.green {
      font-weight: 500;
      color: $maiGreen;
    }
  }
  .audience-options {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 4px 8px !important;
    border-radius: 8px;
    margin-inline-end: 8px;
  }
  .Applicants {
    color: $maiGreen;
    background: $higlightGreen;
  }
  .Recruiters {
    color: #f37637;
    background: #ffe8dc;
  }
  .Companies {
    color: #0099c1;
    background: #e0f8ff;
  }
  .notification-content {
    color: $textGray;
    border: 1px solid $textLightGray;
    border-radius: 10px;
    padding: 12px;
    margin-top: 16px;
  }
}

.error {
  color: red !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  padding: 0 12px !important;
}

.map {
  width: 100%;
}

.single-item {
  // margin-bottom: 16px;
  // &:last-of-type {
  //   margin-bottom: 0;
  // }

  p {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: $textBlack;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .content {
    border: 1px solid $borderColor;
    color: $textGray !important;
    padding: 12px;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    border-radius: 10px;
    line-height: normal;
    margin-bottom: 0;
    height: auto !important;
  }
}

.date {
  p {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    span {
      color: $mainColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.advice {
  // overflow-x: scroll;
}
.option {
  color: #6e7980 !important;

  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: #efefef;
  padding: 8px 30px !important;
}

.Valid {
  color: #2cd6c2 !important;

  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: #e3fdf8;
  padding: 8px 30px !important;
}

.Used {
  color: #134074 !important;

  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: rgba(19, 64, 116, 0.74);
  padding: 8px 30px !important;
}

.Expired {
  color: var(--strawberry-smash, #f2055c) !important;

  // font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 8px;
  background: rgba(242, 5, 92, 0.41);
  padding: 8px 30px !important;
}

.Delivered {
  display: flex;
  height: 37px;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  background: #e3fde5;
  color: #2cd63d !important;
  margin-inline-end: 8px;
}

.Shipping {
  display: flex;
  height: 37px;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  gap: 6px;
  margin-inline-end: 8px;
  align-self: stretch;
  border-radius: 8px;
  background: #f2055c69;
  color: #f2055c !important;
}

.Preparing {
  display: flex;
  height: 37px;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  margin-inline-end: 8px;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  background: #fcffab;
  color: #7a7e00 !important;
}
.status {
  display: flex;
  height: 37px;
  padding: 8px;
  justify-content: center;
  align-items: flex-start;
  margin-inline-end: 8px;
  gap: 6px;
  align-self: stretch;
  border-radius: 8px;
  background: #efefef;
  color: #6e7980 !important;
}

.district {
  color: $mainColor !important;
  font-size: 18px !important;
}
