$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
.button {
  background: $highlightBlue;
  color: $textBlack;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid $highlightBlue;
  width: 100%;
}

.button-reverse {
  background: transparent;
  color: $mainColor;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: 1px solid $mainColor;
  width: 100%;
}
