$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;

.suppliers {
  .title {
    margin-bottom: 32px;
  }
  .main-section {
    margin-top: 32px;
    .options {
      margin-bottom: 32px;
    }
    .input {
      margin-bottom: 16px;

      p {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        &:hover {
          cursor: pointer;
        }
      }
      .input--options {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        margin-top: 0;
      }
      .input--link {
        color: $textGray;
        margin-top: 8px !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-bottom: 8px;
        margin-top: 0;
      }

      span {
        color: $mainColor;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px !important;
      }
      .button {
        margin-top: 32px;
        margin-bottom: 32px;
      }
    }
  }
}

.suppliers-card {
  //   width: 100%;
  height: 200px;
  padding: 16px !important;
  border: 1px solid $mainColor;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  //   align-items: center;
  text-align: center;
  //   justify-content: center;
  img {
    align-self: center;
    justify-self: center;
    border-radius: 50%;
    width: 80px !important;
    height: 80px !important;
    margin-bottom: 16px !important;
  }
  .info {
    p {
      margin-bottom: 0 !important;
      color: $mainColor;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: $textBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.deal-card {
  padding: 16px;
  border-radius: 10px;
  border: 1px solid $mainColor;

  p {
    color: $textBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 0px;
    // border: 1px solid red;
  }
}

.company-profile--data {
  padding: 0;
  //   padding-top: 24px;
  .single-item {
    // direction: auto;
    margin-bottom: 16px;
    padding: 0;
    .category-option {
      border: 2px solid $mainColor;
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      padding: 8px 20px;
      border-radius: 10px;
      margin: 0;
      margin-inline-end: 8px;
      //   margin-top: 16px !important;
    }
    p {
      padding: 0px;
      color: $mainColor;
      margin-bottom: 8px;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    img {
      width: 255px;
      height: 150px;
      margin-inline-end: 8px;
    }

    span {
      color: $textGray;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &.green {
        color: $mainColor;
        font-weight: 500;
      }
      &.icon {
        color: $textGray;
        svg {
          margin-inline-end: 8px;
          transition: 0.3s all;
          &:hover {
            cursor: pointer;
            transform: scale(1.3);
            transition: 0.2s smooth all;
          }
        }
      }
    }
  }
}

.address-card {
  padding: 16px;
  border: 1px solid $textGray;
  border-radius: 10px;
  img {
    width: 100%;
    margin-bottom: 24px;
  }

  h1 {
    color: $textBlack;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
  }

  p {
    color: $textBlack;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 8px;
    &:last-of-type {
      margin-bottom: 0px;
    }
  }
}

.link {
  color: $mainColor;
  font-size: 14px;
}

.table-options {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: max-content !important;
  text-overflow: unset;
  background: white !important;
  z-index: 5;
  position: absolute;
  //   top: 46px;
  border: 1px solid $mainColor;
  text-align: end;
  right: 12px;
  padding: 16px !important;
  // justify-self: self-end !important;
  // align-self: flex-end;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  p {
    width: max-content;
    flex-grow: 1;
    display: flex;
    margin-bottom: 16px !important;
    line-height: normal;
    // padding: 0;
    &:last-of-type {
      margin-bottom: 0 !important;
    }
  }
  // padding: 16px;
}
