$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;

.category-page {
  .main-section {
    // margin-top: 32px !important;
    // padding-top: 32px !important;
    .table-option {
      margin-top: 32px !important;
      margin-bottom: 32px;
    }
    .options {
      margin-bottom: 32px;
    }
    .pagination {
      margin: 24px 0 !important;
    }
  }
}
