.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.custom-container {
  padding: 0px 32px 32px 32px !important;
}

@media (max-width: 1200px) {
  .custom-container {
    padding: 32px 32px 0px 32px !important;

    /* background-color: red; */
  }
}

.errorMsg {
  color: red !important;
  font-size: 13px !important;
  margin-top: 8px !important;
  font-weight: 500 !important;
}
.errorMsg1 {
  color: rgb(157, 6, 6) !important;
  font-size: 13px !important;
  margin-top: 8px !important;
  font-weight: 500 !important;
}
/* 
* {
  border: 1px solid red;
} */

.no-found-data {
  color: var(--sub, #8c8c8c);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 20px !important;

  justify-content: center;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  padding-top: 10px;
}

.navbar-toggler {
  /* padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x); */
  /* font-size: var(--bs-navbar-toggler-font-size); */
  /* line-height: 1; */
  color: white;
  background-color: transparent;
  border: 1px solid white !important;
  /* border-radius: var(--bs-navbar-toggler-border-radius); */
  /* transition: var(--bs-navbar-toggler-transition); */
}
