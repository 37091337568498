$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #151816;
$bacgroundWhite: #ffffff;
.nav {
  // background: $bacgroundWhite;
  padding: 32px 100px;
  // box-shadow: 0px 2px 10px 0px rgba(137, 137, 137, 0.15);
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}
