$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;
$highlightlightBlue: #cfedff;

.area {
  .title {
    margin-bottom: 32px;
  }
  .table-option {
    margin-top: 32px !important;
    margin-bottom: 32px;
  }
  .input {
    //   margin-bottom: 0;
    //   padding-bottom: 0;
    //   margin-bottom: 16px;
    //   border: 1px solid red;
    //   &:first-of-type {
    //     padding-top: 32px;
    //   }

    margin-bottom: 16px;
  }

  .main-data-option {
    background: white;
    color: $mainColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    padding: 16px 8px !important;
    border-radius: 10px;
    margin-bottom: 16px !important;
  }
  .main-data-option1 {
    background: white;
    color: $mainColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 36px;
    padding: 16px 8px !important;
    border-radius: 10px;
    margin-bottom: 0px !important;
  }

  .main-data-option:hover,
  .main-data-option1:hover {
    cursor: pointer;
  }
  .popup-title {
    color: $mainColor;
    font-size: 20px;
    font-weight: 500;
    line-height: 42px;
    letter-spacing: 0em;
  }

  .input-group input::placeholder {
    padding: 5px 0;
  }
  .input-group textarea {
    padding: 8px 5px;
  }

  .main-data-shown {
    background-color: white;
    margin-top: -5px !important;
    margin-bottom: 16px !important;
    border-radius: 0 0 10px 10px;
    // border: 1px solid $highlightBlue;
  }

  .main-data-shown-title {
    color: $mainColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .main-data-shown-title1 {
    color: $mainColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .main-data-shown-subtitle {
    color: $mainColor;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .main-data-shown-btn {
    color: $mainColor;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
  .main-data-shown-btn-delete {
    color: red;
    border: 1px solid red;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    border-radius: 10px;
    padding: 2px 0;
    transition: 0.4s all;
  }
  .main-data-shown-btn-delete:hover .main-data-shown-btn-delete svg {
    opacity: 0;
  }
  .main-data-shown-btn-delete:hover {
    color: white;
    background: red;
  }
}

.popup-title {
  color: $mainColor;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 16px;
}
