$higlightGreen: #e9fff0;
$maiGreen: #26cd61;
$textGray: #949494;
$textBlack: #000;
$mainColor: #134074;
$borderColor: #949494;
$highlightBlue: #91c8e4;
$textLightGray: #d9d9d9;

.suppliers-card {
  //   width: 100%;
  //   height: 200px;
  // height: auto !important;
  padding: 16px !important;
  border: 1px solid $mainColor;
  border-radius: 10px;
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   text-align: center;
  //   justify-content: center;
  img {
    align-self: center;
    justify-self: center;
    border-radius: 50%;
    width: 80px !important;
    height: 80px !important;
    margin-bottom: 16px !important;
  }
  .info {
    p {
      margin-bottom: 0 !important;
      color: $mainColor;
      word-break: break-all;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: $textBlack;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}
